import { useListingsSections } from '@hooks/use-listings-sections';
import useAnalyticsContext from '@hooks/useAnalyticsContext';
import { useAtomValue } from 'jotai';
import React, { FC, Fragment, useEffect } from 'react';
import { connect } from 'react-redux';
import { calculateResponsiveState } from 'redux-responsive';
import AnalyticsConstants from '../../../../../../constants/analytics';
import { VENDOR_REFERRED_MARKET_CODE } from '../../../../../../constants/cookies';
import cookieSetItem from '../../../../../utils/cookie/consentManagementCookie';
import {
	isSemanticSearchCalledAtom,
	storefrontsCountAtom,
} from '../../SemanticSearch/atoms';
import { ListingsSectionsGrid } from './ListingsSectionsGrid';
import Results from './Results';
import SupplementalResults from './Supplemental';
import ZeroResults from './ZeroResults';

interface OwnProps {
	mode?: string;
}

interface StateProps {
	calculateResponsive: typeof calculateResponsiveState;
	category: Redux.Category;
	currentPage: number;
	hasSupplemental: boolean;
	hasZeroResults: boolean;
	isMemberFetching: boolean;
	location: Redux.Location;
	marketCode: string;
	member: Membership.Member;
	totalVendors: number;
}

type SearchResultsProps = OwnProps & StateProps;

export const SearchResults: FC<SearchResultsProps> = (props) => {
	const {
		calculateResponsive,
		category,
		currentPage,
		hasSupplemental,
		hasZeroResults,
		isMemberFetching,
		location,
		marketCode,
		member,
		mode,
		totalVendors,
	} = props;

	const { track } = useAnalyticsContext();
	const isSemanticSearchCalled = useAtomValue(isSemanticSearchCalledAtom);
	const storefrontsCount = useAtomValue(storefrontsCountAtom);
	const isListingsSections = useListingsSections();

	useEffect(() => {
		const oneHour = new Date().getTime() + 60 * 60000;
		cookieSetItem(VENDOR_REFERRED_MARKET_CODE, marketCode, {
			expires: new Date(oneHour),
		});

		calculateResponsive(window);
	}, []);

	useEffect(() => {
		const memberId = member ? member.id : '';

		const trackVendorCategoryViewed = () => {
			const vendorCategoryLevel = isSemanticSearchCalled ? 'semantic' : 'city';
			const properties = {
				displayLocationName: `${location.city}, ${location.stateCode}`,
				displayMarketCode: marketCode,
				pageNumber: currentPage,
				vendorCategoryCode: category.code,
				vendorCategoryLevel,
				vendorResultsCount: isSemanticSearchCalled
					? storefrontsCount
					: totalVendors,
				memberId,
			};

			track({
				event: AnalyticsConstants.VENDOR_CATEGORY_VIEWED,
				properties,
			});
		};

		if (!isMemberFetching) {
			trackVendorCategoryViewed();
		}
	}, [isMemberFetching, track, isSemanticSearchCalled]);

	const resultsGrid = isListingsSections ? (
		<ListingsSectionsGrid />
	) : (
		<Results mode={mode} />
	);

	return (
		<Fragment>
			{resultsGrid}
			{hasZeroResults && <ZeroResults hasSupplemental={hasSupplemental} />}
			{hasSupplemental && <SupplementalResults />}
		</Fragment>
	);
};
export const mapStateToProps = (state: Redux.State) => ({
	category: state.category,
	currentPage: state.search.pagination.page,
	hasSupplemental: state.search.supplemental.length > 0,
	hasZeroResults: state.search.vendors.length === 0,
	location: state.location,
	marketCode: state.settings.marketCode,
	totalVendors: state.search.total,
	member: state.membership.member,
	isMemberFetching: state.membership.inProgress.FETCH,
});

const mapDispatchToProps = {
	calculateResponsive: calculateResponsiveState,
};

export default connect(mapStateToProps, mapDispatchToProps)(SearchResults);

import cx from 'classnames';
import Styles from './styles.scss';

const createClassName = (
	isSticky: boolean,
	showMapView: boolean,
	isListingsSections: boolean,
) => {
	if (showMapView) {
		return cx(Styles.filterMapContainer, Styles.stickyContainer);
	}
	return cx(
		isListingsSections
			? Styles.listingsSectionsInnerContainer
			: Styles.innerContainer,
		isSticky ? Styles.stickyContainer : undefined,
	);
};

export { createClassName };
